/* ------------------------------
	// LoginView popup 
	------------------------------ */
.modal-open {
    /*EJ 108404: Fikser bug med IOS hvor det ikke er mulig å scrolle i modalbokser*/
    position: fixed;
    width: 100%;
}

#center-popup .customer-registration {
    padding: 1.125em 0;
    border-radius: 3px;
}

.customer-registration {
    min-height: 22em;
}

    .customer-registration .form-control {
        height: 38px;
    }

.reg-textbox {
    margin-top: 10px;
}

.input-group .reg-textbox {
    margin-top: 0;
}

.address-search .btn {
    height: 38px;
    width: 80px;
}

.smart-label .active .reg-textbox {
    padding-top: 12px;
    padding-bottom: 0;
    background-color: #FDFEDB; /* #f3f3f3;*/
    font-weight: bold;
}

    .smart-label .active .reg-textbox.form-control[disabled] {
        background-color: #eee !important;
    }

.smart-label .active.inactive .reg-textbox {
    font-weight: normal;
}

.smart-label .inactive .reg-textbox {
    padding-top: 6px;
    background-color: #FDFEDB; /* #f3f3f3;*/
}

    .smart-label .inactive .reg-textbox::-webkit-input-placeholder { /* WebKit browsers */
        color: #666;
    }

    .smart-label .inactive .reg-textbox:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
        color: #666;
        opacity: 1;
    }

    .smart-label .inactive .reg-textbox::-moz-placeholder { /* Mozilla Firefox 19+ */
        color: #666;
        opacity: 1;
    }

    .smart-label .inactive .reg-textbox:-ms-input-placeholder { /* Internet Explorer 10+ */
        color: #666;
    }

.smart-label .inactive #delCountry {
    background-color: #eee;
}

.reg-dropdown {
    margin-top: 10px;
}

.customer-registration .requiredMsg-container {
    padding-left: 0;
}

.registration-type {
    text-align: right;
    margin: 0;
}

#lightboxCustomerRegistration .registration-type {
    padding-right: 0;
}

#businessRegistrationView .address-type-title {
    margin-left: 16px;
    position: relative;
    top: 5px;
}

#lightboxCustomerRegistration .private-reg-d-address {
    display: none;
}

#lightboxCustomerRegistration #privateCustomerRegistration .delivery-address {
    display: none !important;
}

#lightboxCustomerRegistration #businessRegistrationView .business-reg-d-name {
    display: none;
}

/* altBusinessRegistration */
#businessRegistrationView .altBusinessRegistration input:not(#addressSearchCompany, .search) {
    background: #eeeeee;
}

#businessRegistrationView .altBusinessRegistration.searched #companyContactPerson input {
    background: #FDFEDB;
}

#businessRegistrationView .altBusinessRegistration .searched input {
    background: #FDFEDB;
}


#businessRegistrationView .customer-exist-dialog {
    margin: 0.715em 1.15em 0;
    padding: 1.15em;
    background-color: #eee;
    font-size: 1.5rem;
    font-weight: 800;
}


.altBusinessRegistration .discount-code-field.smart-label,
.altBusinessRegistration .checkbox-fieldset.business-reg-d-address {
    display: none;
}

.businessCustomerRequestAccessSent {
    display: grid;
    place-items: center;
    padding: 2rem;
}

.businessCustomerRequestAccessSent p {
    font-size: 1.5rem;
}


#businessRegistrationView .altBusinessRegistration.searched #phone.fieldOpen {
    background: #FDFEDB;
}

/* altBusinessRegistration end */
/*#region Smart label box */
.smart-label .active label, .smart-label .inactive label {
    position: absolute;
    top: 0;
    left: 20px;
    display: none;
    color: #666666;
    font-size: 0.8em;
    z-index: 9;
}

.smart-label .inactive-old label {
    position: absolute;
    top: 20px;
    left: 15px;
    display: block;
    color: #999999;
    font-size: 1em;
}

.smart-label .textbox {
    margin: 10px 0 0 0;
    width: 100%;
    height: 40px;
    border: 1px solid #bbbbbb;
    background: -moz-linear-gradient(top, rgba(255, 255, 255, 1) 0, rgba(237, 237, 237, 1) 0, rgba(243, 243, 243, 1) 1%, rgba(255, 255, 255, 1) 100%);
    background: -webkit-linear-gradient(top, rgba(255, 255, 255, 1) 0, rgba(237, 237, 237, 1) 0, rgba(243, 243, 243, 1) 1%, rgba(255, 255, 255, 1) 100%);
    background: -o-linear-gradient(top, rgba(255, 255, 255, 1) 0, rgba(237, 237, 237, 1) 0, rgba(243, 243, 243, 1) 1%, rgba(255, 255, 255, 1) 100%);
    background: -ms-linear-gradient(top, rgba(255, 255, 255, 1) 0, rgba(237, 237, 237, 1) 0, rgba(243, 243, 243, 1) 1%, rgba(255, 255, 255, 1) 100%);
    font-size: 1.2em;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

    .smart-label .textbox:focus {
        border-color: #0080C9 !important;
        border-color: rgba(0, 128, 201, 0.8) !important;
        -webkit-box-shadow: 0 0 8px #0080c9;
        -webkit-box-shadow: 0 0 8px rgba(0, 128, 201, 0.6);
        -ms-box-shadow: 0 0 8px #0080c9;
        -ms-box-shadow: 0 0 8px rgba(0, 128, 201, 0.6);
        box-shadow: 0 0 8px #0080C9;
        box-shadow: 0 0 8px rgba(0, 128, 201, 0.6);
        outline: 0 none;
    }

.smart-label .error .textbox:focus {
    border-color: #ff0000 !important;
    border-color: rgba(255, 0, 0, 0.6) !important;
    -webkit-box-shadow: 0 0 8px #ff0000;
    -webkit-box-shadow: 0 0 8px rgba(255, 0, 0, 0.6);
    -ms-box-shadow: 0 0 8px #ff0000;
    -ms-box-shadow: 0 0 8px rgba(255, 0, 0, 0.6);
    box-shadow: 0 0 8px #ff0000;
    box-shadow: 0 0 8px rgba(255, 0, 0, 0.6);
    outline: 0 none;
}

.qtip-cust {
    font-size: 0.8em;
    padding: 5px;
    line-height: 20px;
}

.smart-label .active .textbox {
    padding: 12px 5px 0 10px;
}

.smart-label .active .mc-btn {
    top: -7px !important;
}

.focus {
    border-color: #0080C9 !important;
    border-color: rgba(0, 128, 201, 0.8) !important;
    -webkit-box-shadow: 0 0 8px rgba(0, 128, 201, 0.6);
    -ms-box-shadow: 0 0 8px #0080c9;
    -ms-box-shadow: 0 0 8px rgba(0, 128, 201, 0.6);
    box-shadow: 0 0 8px #0080C9;
    box-shadow: 0 0 8px rgba(0, 128, 201, 0.6);
    outline: 0 none;
}

.smart-label .inactive .textbox, .smart-label .inactive-old .textbox {
    padding: 5px 5px 5px 10px;
}

.smart-label .textbox:disabled {
    background: #ddd;
}

.smart-label .mc-btn.search {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    height: 40px;
    position: relative;
    right: 10px;
    top: -2px;
    width: 80px;
}

.smart-label .address-search .textbox {
    width: 85%;
}

.smart-label .inactive i, .smart-label .active i, .smart-label .inactive-old i {
    position: relative;
    top: 10px;
    right: 15px;
    display: block;
    float: right;
    width: 0;
    color: #cccccc;
    font-size: 0.7em;
    line-height: 0;
}

.smart-label .error > input {
    border: 1px solid red;
}

.smart-label .error > i {
    color: red;
}

.smart-label .error > label {
    color: red;
}

.smart-label .ok > i {
    color: green;
}

.smart-label .ok > label {
    color: green;
}


/*#endregion */




/*#region Customer registration */
.customer-registration h2 {
    margin: 0 0 5px 0;
    font-size: 1.5em;
}

#businessRegistrationView, #privateCustomerRegistration {
    float: left;
    width: 100%;
}

.customer-registration .requiredMsg {
    color: #999999;
    /*font-size: 0.8em;*/
    /*margin: 20px 0 0 12px;*/
}

    .customer-registration .requiredMsg > span {
        font-weight: bold;
        font-size: 0.8em;
    }

.customer-registration .checkbox {
    display: block;
    overflow: visible;
    margin-top: 5px;
    height: 20px;
    margin-left: -18px; /*5px;*/
    margin-bottom: 0;
}

    .customer-registration .checkbox > input {
        position: relative;
        display: block;
        cursor: pointer;
    }

    .customer-registration .checkbox > label {
        /*position: relative;
	left: 15px;
	width: inherit;
	font-size: 0.9em;
	cursor: pointer;
	font-size: 1.1em;*/
    }

.customer-registration .company-contact-person .checkbox {
    margin-top: 15px;
}

.customer-registration .delivery-address .checkbox {
    margin-top: 15px;
}

.customer-registration .action-buttons {
    padding-bottom: 40px;
    text-align: right;
}

.customer-registration .checkbox-fieldset {
    margin-top: 5px;
    margin-bottom: 15px;
}

.customer-registration .discount-code {
    padding-bottom: 20px;
}

.customer-registration  .action-buttons  .btn-link  {
    margin-right: 20px;
    color: #333333;
}

.customer-registration .company-contact-person {
    margin: 1.125em;
    border: 1px solid #dddddd;
    padding: 10px 5px 10px;
}

.customer-registration legend {
    color: #333333;
    font-size: 1em;
    padding: 0 10px;
    border: none;
    width: initial;
    margin: 0;
}

.customer-registration .delivery-address {
    border: 1px solid #dddddd;
    display: none;
    margin-bottom: 20px;
    margin-top: 0;
    padding: 5px 5px 20px;
}

/*#endregion */


/*#region Customer registration confirm */
.customer-registration-confirm {
    padding: 40px;
}

    .customer-registration-confirm h2 {
        margin: 0 0 1em 0;
        font-size: 1.5em;
    }

    .customer-registration-confirm p {
        margin: 10px 0;
        font-size: 1.1em;
    }

    .customer-registration-confirm .close-button {
        text-align: right;
        position: relative;
        right: 20px;
        margin-bottom: 10px;
    }

    .customer-registration-confirm .change-password div {
        margin-bottom: 10px;
    }

.checkout .customer-registration .requiredMsg {
    display: none;
}


.register-discount-ok .fa {
    color: #5cb85c;
    font-size: 22px;
    vertical-align: bottom;
}

.register-discount-error .fa {
    color: #f0ad4e;
    font-size: 22px;
    vertical-align: bottom;
}

.blocked-private-customer,
.blocked-business-customer,
.blocked-customer {
    display: none;
}

.checkout .blocked-private-customer,
.checkout .blocked-business-customer,
.checkout .blocked-customer {
    display: block;
    font-weight: bold;
    color: #FF0000;
    padding-bottom: 15px;
    width: 100%;
}

.blocked-save-btn {
    margin: 15px 0;
}

    .blocked-save-btn a {
        white-space: inherit;
    }


.blocked-customer,
.blocked-customer-info {
    display: inline-block;
    text-align: center;
}

.block-cust-private-pub,
.block-cust-business-pub,
.block-cust-readonly-pub {
    font-weight: normal;
    color: #333;
    padding: 0;
    width: 100%;
    height: auto;
}


.customer-type {
    font-size: 18px;
}

    .customer-type .btn {
        padding: 8px 50px;
        font-size: 16px;
        line-height: 1.3333333;
        border-radius: 6px;
    }

.customer-type-info {
    padding-top: 50px;
    padding-bottom: 10px;
}

.payments .customer-registration .customer-type .btn-group {
    width: initial
}

.business-reg-dealer {
    padding-bottom: 10px;
}

/*#endregion */
/*==================================================
=            Bootstrap 3 Media Queries             =
==================================================*/
/*==========  Mobile First Method  ==========*/
/* Custom, iPhone Retina */
@media only screen and (min-width : 320px) {
}

/* Extra Small Devices, Phones */
@media only screen and (min-width : 480px) {
}

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {

    .private-reg-firtname,
    .private-reg-address1,
    .private-reg-zipcode,
    .private-reg-email,
    .private-reg-d-address1,
    .private-reg-d-zipcode,
    .private-reg-birthdate,
    .private-reg-prefix,
    .business-reg-prefix,
    .business-reg-address1,
    .business-reg-zipcode,
    .business-reg-orgno,
    .business-reg-c-firstname,
    .discount-code,
    .business-reg-name2 {
        padding-right: 0;
    }

    .smart-label .active label, .smart-label .inactive label {
        top: 12px;
    }

    .customer-registration .discount-code {
        padding-bottom: 0;
    }

    .customer-registration .action-buttons {
        padding-top: 20px;
        padding-bottom: 15px;
    }
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
}

/*==================================================
=  END       Bootstrap 3 Media Queries             =
==================================================*/

/* AskForPrice-skjema opplasting av filer */
.askforprice-fileupload {
    padding-top: 15px;
}
.askforprice-fileupload-header {
    font-weight: bold;
}
.askforprice-fileupload-filesizenotice {
    padding-bottom: 10px;
}
.askforprice-fileupload-filenames {
    padding-top: 10px;
}
.askforprice-fileupload-filenames-caption {
    font-weight: bold;
}
